import { lazy } from "react";

// project imports
import Loadable from "./../ui-component/Loadable";
import MinimalLayout from "./../layout/MinimalLayout";

// login option 3 routing
const AuthLogin3 = Loadable(
  lazy(() => import("./../views/pages/authentication/authentication3/Login3"))
);
const ForgotPassword = Loadable(
  lazy(() =>
    import("./../views/pages/authentication/authentication3/ForgotPassword")
  )
);
const AuthRegister3 = Loadable(
  lazy(() =>
    import("./../views/pages/authentication/authentication3/Register3")
  )
);
const ExternalSubscribe = Loadable(
  lazy(() => import("./../views/external-subscribe/ExternalSubscribe"))
);
const ExternalSubscribeEmail = Loadable(
  lazy(() =>
    import("./../views/external-subscribe-email/ExternalSubscribeEmail")
  )
);
const ExternalSubscribeSms = Loadable(
  lazy(() => import("./../views/external-subscribe-sms/ExternalSubscribeSms"))
);
const ExternalPolygonView = Loadable(
  lazy(() => import("./../views/external-polygon-view/ExternalPolygonView"))
);
const ExternalPolygonDetail = Loadable(
  lazy(() => import("./../views/external-polygon-detail/ExternalPolygonDetail"))
);
const ExternalParo = Loadable(
  lazy(() => import("./../views/external-paro/ExternalParo"))
);
const ExternalParoResult = Loadable(
  lazy(() => import("./../views/external-paro-result/ExternalParoResult"))
);

const SmartLogin = Loadable(
  lazy(() =>
    import("./../views/pages/authentication/authentication3/SmartLogin.js")
  )
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    // {
    //   path: "/login",
    //   element: <AuthLogin3 />,
    // },
    {
      path: "/login",
      element: <SmartLogin />,
    },
    {
      path: "/forgot",
      element: <ForgotPassword />,
    },
    {
      path: "/pages/register/register3",
      element: <AuthRegister3 />,
    },
    {
      path: "/subscribe",
      element: <ExternalSubscribe />,
    },
    {
      path: "/subscribe-email",
      element: <ExternalSubscribeEmail />,
    },
    {
      path: "/subscribe-sms",
      element: <ExternalSubscribeSms />,
    },
    {
      path: "/polygon-view",
      element: <ExternalPolygonView />,
    },
    {
      path: "/polygon-detail",
      element: <ExternalPolygonDetail />,
    },
    {
      path: "/paro",
      element: <ExternalParo />,
    },
    {
      path: "/paro-result",
      element: <ExternalParoResult />,
    },
  ],
};

export default AuthenticationRoutes;
