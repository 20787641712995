import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

// project imports
import Sidebar from "./Sidebar";
import { drawerWidth } from "./../../store/constant";
import { SET_MENU } from "./../../store/actions";

// assets
import Auth from "./../../utils/auth.js";
import SmartHeader from "./SmartHeader";
import { css, Global } from "@emotion/react";
// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...(open && {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = true;

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);
  function renderRouting() {
    if (Auth.isAuth()) {
      return <Outlet />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <CssBaseline />
      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        <Global
          styles={css`
            .ColorPicker-MuiBox-root {
              background-color: ${theme.palette.background.paper} !important;
            }
            .ColorPicker-MuiInputBase-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiTypography-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiFormLabel-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiButton-root {
              box-shadow: 0px 0px 10px grey !important;
            }
          `}
        />
        <SmartHeader />
        {renderRouting()}
      </Main>
    </Box>
  );
};

export default MainLayout;
