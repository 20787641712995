// assets

import IconHome from "../assets/images/9_menu_icons/1home.png";
import IconBell from "../assets/images/9_menu_icons/2oznameni.png";
import IconPencil from "../assets/images/9_menu_icons/3upravaAplikace.png";
import IconLayoutGrid from "../assets/images/9_menu_icons/4moduly.png";
import IconUser from "../assets/images/9_menu_icons/5ucty.png";
import IconShare from "../assets/images/9_menu_icons/6tipy.png";
import IconChartHistogram from "../assets/images/9_menu_icons/7statistika.png";
import IconSettings from "../assets/images/9_menu_icons/8nastaveni.png";
import IconCirclePlus from "../assets/images/9_menu_icons/9doplnkoveModuly.png";
import IconHomeSelected from "../assets/images/10_white_drawer_icons/1home.png";
import IconBellSelected from "../assets/images/10_white_drawer_icons/2oznameni.png";
import IconPencilSelected from "../assets/images/10_white_drawer_icons/3upravaAplikace.png";
import IconLayoutGridSelected from "../assets/images/10_white_drawer_icons/4moduly.png";
import IconUserSelected from "../assets/images/10_white_drawer_icons/5ucty.png";
import IconShareSelected from "../assets/images/10_white_drawer_icons/6tipy.png";
import IconChartHistogramSelected from "../assets/images/10_white_drawer_icons/7statistika.png";
import IconSettingsSelected from "../assets/images/10_white_drawer_icons/8nastaveni.png";
import IconCirclePlusSelected from "../assets/images/10_white_drawer_icons/9doplnkoveModuly.png";

// constant
const icons = {
  IconHome,
  IconBell,
  IconPencil,
  IconLayoutGrid,
  IconUser,
  IconShare,
  IconChartHistogram,
  IconSettings,
  IconCirclePlus,
  IconHomeSelected,
  IconBellSelected,
  IconPencilSelected,
  IconLayoutGridSelected,
  IconUserSelected,
  IconShareSelected,
  IconChartHistogramSelected,
  IconSettingsSelected,
  IconCirclePlusSelected,
};

// ==============================|| MAIN MENU ITEMS ||============================== //

const main = (navigate, appState) => ({
  id: "main",
  type: "group",

  children: [
    {
      id: "home",
      title: "home",
      type: "item",
      icon: icons.IconHome,
      selectedIcon: icons.IconHomeSelected,
      breadcrumbs: false,
      onClick: () => {
        navigate("/app-sections", {
          state: {
            idApp: appState._id,
            app: appState,
          },
        });
      },
    },
    {
      id: "notifications",
      title: "notifications",
      type: "item",
      icon: icons.IconBell,
      selectedIcon: icons.IconBellSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/notifications", {
          state: {
            notificationSegments: appState.notificationSegments,
            idApp: appState._id,
            app: appState,
          },
        }),
    },
    {
      id: "app-edit",
      title: "appEdit",
      type: "item",
      icon: icons.IconPencil,
      selectedIcon: icons.IconPencilSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/smart-screens", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "modules",
      title: "modules",
      type: "item",
      icon: icons.IconLayoutGrid,
      selectedIcon: icons.IconLayoutGridSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/app-management", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "accounts",
      title: "accounts",
      type: "item",
      icon: icons.IconUser,
      selectedIcon: icons.IconUserSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/app-user-list", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "tips",
      title: "tips",
      type: "item",
      icon: icons.IconShare,
      selectedIcon: icons.IconShareSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/smart-care", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "statisitcs",
      title: "statisitcs",
      type: "item",
      icon: icons.IconChartHistogram,
      selectedIcon: icons.IconChartHistogramSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/app-statistics", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "settings",
      title: "settings",
      type: "item",
      icon: icons.IconSettings,
      selectedIcon: icons.IconSettingsSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/app-settings", {
          state: { idApp: appState._id, app: appState },
        }),
    },
    {
      id: "extras",
      title: "extras",
      type: "item",
      icon: icons.IconCirclePlus,
      selectedIcon: icons.IconCirclePlusSelected,
      breadcrumbs: false,
      onClick: () =>
        navigate("/app-sections/app-extras", {
          state: { idApp: appState._id, app: appState },
        }),
    },
  ],
});

export default main;
