/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme, isDarkMode) {
  return {
    // mode: theme?.customization?.navType,
    mode: isDarkMode ? "dark" : "light",
    caption: isDarkMode ? "dark" : "grey",
    common: {
      black: isDarkMode ? theme.colors?.darkPaper : theme.colors?.darkPaper,
      white: theme.colors?.paper,
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      200: theme.colors?.primary200,
      800: theme.colors?.primary800,
    },
    secondary: {
      light: theme.colors?.secondaryLight,
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark,
      200: theme.colors?.secondary200,
      800: theme.colors?.secondary800,
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark,
    },
    orange: {
      light: theme.colors?.orangeLight,
      main: theme.colors?.orangeMain,
      dark: theme.colors?.orangeDark,
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark,
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark,
    },
    grey: {
      50: isDarkMode ? theme.colors?.grey900 : theme.colors?.grey50,
      100: isDarkMode ? theme.colors?.grey800 : theme.colors?.grey100,
      500: isDarkMode ? theme.colors?.grey300 : theme.darkTextSecondary,
      600: isDarkMode ? theme.colors?.grey200 : theme.heading,
      700: isDarkMode ? theme.colors?.grey100 : theme.darkTextPrimary,
      900: isDarkMode ? theme.colors?.grey50 : theme.textDark,
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper,
    },
    text: {
      primary: isDarkMode ? theme.colors?.grey50 : theme.darkTextPrimary,
      secondary: isDarkMode ? theme.colors?.grey100 : theme.darkTextSecondary,
      dark: isDarkMode ? theme.colors?.grey200 : theme.textDark,
      hint: isDarkMode ? theme.colors?.grey300 : theme.colors?.grey100,
      timestamp: isDarkMode ? theme.colors?.grey50 : "#41B",
      default: isDarkMode ? theme.colors?.paper : theme.textDark,
      tooltip: isDarkMode ? theme.colors?.paper : "#616161",
    },
    background: {
      paper: isDarkMode ? theme.colors?.grey900 : theme.paper,
      default: isDarkMode ? theme.colors?.secondaryDark : theme.colors?.paper,
      breadcrumbs: isDarkMode ? theme.colors?.secondaryDark : "#F1F5F7",
    },
    borderColor: {
      textField: isDarkMode ? theme.colors?.paper : "black",
      default: isDarkMode ? theme.colors?.paper : "light",
      greyBorder: isDarkMode ? "#6c6a6a" : "light",
    },
    borderStyle: isDarkMode ? "solid" : "none",
  };
}
