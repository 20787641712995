// assets
import { IconFile, IconUsers, IconDeviceMobile } from "@tabler/icons";
import Auth from "./../utils/auth.js";
// constant
const icons = { IconFile, IconUsers, IconDeviceMobile };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

let accounts = {
  id: "apps",
  title: "Správa aplikací",
  type: "group",

  children: [
    {
      id: "apps",
      title: "Aplikace",
      type: "item",
      url: "/apps",
      icon: icons.IconDeviceMobile,
      breadcrumbs: false,
    },
  ],
};

export default accounts;
