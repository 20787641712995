import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import labels from "../../../../../languages/languages";
import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "./../../../../../store/actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, onClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const location = useLocation();

  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  function renderText(key) {
    let value = labels.mainDashboard[key];
    let label = value?.find((l) => l.language === selectedLanguage);
    return label ? label.label : key;
  }

  const isSelected =
    customization.isOpen.findIndex((id) => id === item.id) > -1;

  const itemIcon =
    typeof item.icon === "string" ? (
      <img
        src={
          isSelected || customization.theme === "dark"
            ? item.selectedIcon
            : item.icon
        }
        alt={item.title}
        style={{ width: 30, height: 30 }}
      />
    ) : (
      <FiberManualRecordIcon
        sx={{
          width:
            customization.isOpen.findIndex((id) => id === item?.id) > -1
              ? 8
              : 6,
          height:
            customization.isOpen.findIndex((id) => id === item?.id) > -1
              ? 8
              : 6,
        }}
        fontSize={level > 0 ? "inherit" : "medium"}
      />
    );

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: true });
    if (onClick) onClick();
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 1,
          marginBottom: 1,
          backgroundColor: isSelected
            ? "#4cb85c"
            : theme.palette.background.default,
          transition: "none",
          borderStyle: theme.palette.borderStyle,
          borderColor: theme.palette.borderColor.greyBorder,
          "&:hover": {
            backgroundColor: "#4cb85c",
            color: "#fff",
            "& .MuiListItemButton-root": {
              backgroundColor: "#4cb85c",
              color: "#fff",
            },
            "& .MuiListItemIcon-root": {
              color: "#fff",
            },
            "& .MuiTypography-root": {
              color: "#fff",
            },
          },
        }}
      >
        <ListItemButton
          // {...listItemProps}
          disabled={item.disabled}
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            color: isSelected ? "#fff" : "inherit",
            transition: "none",
            "&:hover": {
              backgroundColor: "#4cb85c",
              color: "#fff",
              "& .MuiListItemIcon-root": {
                color: "#fff",
              },
              "& .MuiTypography-root": {
                color: "#fff",
              },
            },
          }}
          onClick={() => itemHandler(item.id)}
        >
          <ListItemIcon
            sx={{
              my: "auto",
              display: "flex",
              justifyContent: "center",
              color: isSelected ? "#fff" : theme.palette.text.default,
            }}
          >
            {itemIcon}
          </ListItemIcon>
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
            primary={
              <Typography
                variant={
                  customization.isOpen.findIndex((id) => id === item.id) > -1
                    ? "h5"
                    : "body1"
                }
                align="center"
                sx={{
                  whiteSpace: "normal",
                  overflow: "visible",
                  textAlign: "center",
                  color: isSelected ? "#fff" : theme.palette.text.default,
                }}
              >
                {renderText(item.title)}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )
            }
          />
          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      </Paper>
    </Box>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  onClick: PropTypes.func,
};

export default NavItem;
