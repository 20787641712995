import { useLocation, useNavigate } from "react-router";
import Auth from "../../../../utils/auth";
import { useSelector } from "react-redux";
import { useState } from "react";

// material-ui
import {
  Box,
  CircularProgress,
  Drawer,
  useMediaQuery,
  IconButton,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import useMenuItems from "./../../../../menu-items";
import labels from "../../../../languages/languages";

//assets
import IconLogout from "../../../../assets/images/10_white_drawer_icons/10odhlaseni.png";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ appState }) => {
  const menuItems = useMenuItems(appState);
  const navigate = useNavigate();

  const customization = useSelector((state) => state.customization);
  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  function renderText(key) {
    let value = labels.mainDashboard[key];
    let label = value.find((l) => l.language === selectedLanguage);
    return label.label;
  }

  const handleLogout = async () => {
    Auth.signout();
    navigate("/login");
  };

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <Box key={item.id}>
            <NavGroup
              key={item.id}
              item={item}
              appExtras={appState?.useAppExtras}
            />
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                marginBottom: 1,
                backgroundColor: "#696969",
                color: "#fff",
                transition: "none",
                "&:hover": {
                  backgroundColor: "#696969",
                  color: "#fff",
                  "& .MuiListItemButton-root": {
                    backgroundColor: "#696969",
                    color: "#fff",
                  },
                  "& .MuiListItemIcon-root": {
                    color: "#fff",
                  },
                  "& .MuiTypography-root": {
                    color: "#fff",
                  },
                },
              }}
            >
              <ListItemButton
                color="inherit"
                onClick={handleLogout}
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    my: "auto",
                    display: "flex",
                    justifyContent: "center",
                    color: "#fff",
                  }}
                >
                  <img
                    src={IconLogout}
                    alt="logout"
                    style={{ width: 30, height: 30 }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    align="center"
                    sx={{
                      whiteSpace: "normal",
                      overflow: "visible",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {renderText("logout")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Paper>
          </Box>
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
