// assets
import { IconFile, IconUsers } from "@tabler/icons";
import IconHome from "../assets/images/9_menu_icons/1home.png";
import IconHomeSelected from "../assets/images/10_white_drawer_icons/1home.png";
import IconUser from "../assets/images/9_menu_icons/5ucty.png";
import IconUserSelected from "../assets/images/10_white_drawer_icons/5ucty.png";
import Auth from "./../utils/auth.js";
// constant
const icons = { IconHome, IconHomeSelected, IconUser, IconUserSelected };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const accounts = (navigate, idSubject) => ({
  id: "accounts",
  type: "group",

  children: [
    {
      id: "dashboard",
      title: "dashboard",
      type: "item",
      icon: icons.IconHome,
      selectedIcon: icons.IconHomeSelected,
      breadcrumbs: false,
      onClick: () => {
        navigate("/", { state: { idSubject: idSubject } });
      },
    },
    {
      id: "subjects",
      title: "subjects",
      type: "item",
      icon: icons.IconUser,
      selectedIcon: icons.IconUserSelected,
      breadcrumbs: false,
      onClick: () => {
        navigate("/subjects", { state: { idSubject: idSubject } });
      },
    },
    // {
    //   id: "accounts",
    //   title: "accounts",
    //   type: "item",
    //   icon: icons.IconUser,
    //   selectedIcon: icons.IconUserSelected,
    //   breadcrumbs: false,
    //   onClick: () =>
    //     navigate("/app-sections/app-user-list", {
    //       state: { idApp: appState._id, app: appState },
    //     }),
    // },
  ],
});

// if (Auth.getRole() === "root") {
// } else {
//   accounts = accountsAdmin;
// }
export default accounts;
