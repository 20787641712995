import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Drawer,
  useMediaQuery,
  Avatar,
  ButtonBase,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import { drawerWidth } from "./../../../store/constant";
import { useLocation } from "react-router";
import { useRef } from "react";
import Auth from "../../../utils/auth";
import { IconMenu2 } from "@tabler/icons";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const user = useRef(Auth.getUser());
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const location = useLocation();

  // const mainState =

  const appState = location?.state?.app;

  function renderToggler() {
    if (user.current && user.current.role === "root") {
      return (
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: "#ffffff",
              color: "#4cb85c",
              "&:hover": {
                background: "#4cb85c",
                color: "#ffffff",
              },
            }}
            onClick={drawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      );
    } else {
      return [];
    }
  }

  const drawer = (
    <>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: "100vh",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          {matchesSM ? renderToggler() : null}
          {/* {appState ? (
            <MenuList appState={appState} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          )} */}
          <MenuList appState={appState} />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          {appState ? (
            <MenuList appState={appState} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
      }}
      aria-label="mailbox folders"
    >
      {matchesSM ? renderToggler() : null}
      <Drawer
        container={container}
        variant={"persistent"}
        anchor="left"
        open={!matchesSM ? true : drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: theme.palette.borderStyle,
            borderLeft: theme.palette.borderStyle,
            borderWidth: "1px",
            height: "100vh",
            borderColor: theme.palette.borderColor.greyBorder,
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
