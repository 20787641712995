// assets
import { IconUsers, IconHome, IconFile } from "@tabler/icons";

// constant
const icons = { IconUsers, IconHome, IconFile };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "subjects",
      title: "Subjekty",
      type: "item",
      url: "/subjects",
      icon: icons.IconFile,
      breadcrumbs: false,
    },
    {
      id: "users",
      title: "Uživatelé",
      type: "item",
      url: "/users",
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "sample-page",
      title: "Sample Page",
      type: "item",
      url: "/sample-page",
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "documentation",
      title: "Documentation",
      type: "item",
      url: "https://codedthemes.gitbook.io/berry/",
      icon: icons.IconHome,
      external: true,
      target: true,
    },
  ],
};

export default other;
