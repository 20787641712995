import CryptoJS from "crypto-js";

const secure = {
  secret: "255a714258c30f4550df8502753602c0",
  subscriptionToken: "65084ddd6592f39df6561f2b",
  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secret).toString();
  },
  decrypt(data) {
    let bytes = CryptoJS.AES.decrypt(data, this.secret);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
};

export default secure;
