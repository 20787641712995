import React from "react";
import ReactDOM from "react-dom";

// third party
import {HashRouter} from "react-router-dom";
import {Provider} from "react-redux";

// project imports

import App from "./App";
import {store} from "./store";

// style + assets
import "./assets/scss/style.scss";
import config from "./config";
//import icons from "react-web-vector-icons/fonts";
require("react-web-vector-icons/fonts");

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter basename={config.basename}>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
